import React, { useState } from 'react'

function Section (props) {
  const [classSup] = useState(props.classSup || '')
  const [content] = useState(props.content || '')

  const getContent = () => {
    if (content) {
      return content
    }
    return ''
  }

  return (
    <section className={`block-section ${classSup}`}>
      {getContent()}
    </section>
  )
}

export default Section
