/* eslint-disable jsx-a11y/anchor-is-valid */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import React from 'react'
import { BackgroundImg, Img } from 'react-cloudimage-responsive'
import Section from './components/Section'
import './scss/App.scss'
import logo_urbasolar from './img/logo_urbasolar.png'
import logo_parents_zens_rond from './img/logo_parents_zens_rond.png'
import mpec_logo_new_2 from './img/mpec_logo_new_2.png'

function App () {

  const getContentBlueBand = () => {
    return (
      <div className="d-flex justify-content-center w-100">
        <div className="col-md-8 col-md-offset-2 text-center single-line">
          <a href={process.env.REACT_APP_URL_UJCALC} target="_blank" rel="noopener noreferrer" className="btn btn-ghost btn-md">Trouver des activités pour mes enfants<i className="fa fa-arrow-right ml-3"></i></a>
        </div>
      </div>
    )
  }

  const getSection1 = () => {
    return (

      <div className="max-h d-flex w-100 justify-content-between flex-column flex-md-row">
        <div className="col-sm-12 col-md-6 block-txt-simple text-center shadow max-w">
          <img src={mpec_logo_new_2} alt="Logo maplaceencreche" className="logo-size mb-4"/>
          <p>Urbasolar met à votre disposition de nouveaux services innovants
            dédiés à la parentalité.
            Retrouvez l'ensemble des informations liées à ces nouveaux services et inscrivez-vous
            aux conférences et ateliers proposés.
          </p>
        </div>
        <div className="col-sm-12 col-md-6 mt-5 mt-md-0 max-w imgRadius no-padMobile">
          <Img
            src={'enfant-livre.jpg'}
            ratio={1}
            params={
              {
                gravity: 'auto',
              }
            }
            sizes={
              {
                '(max-width: 700px)': { h: '10vh' },
                '(min-width: 701px)': { w: '450px', h: '350px' }
              }
            }
          />
        </div>
      </div>
    )
  }

  const getSection2 = () => {
    return (
      <div className="container">
      <div className="no-gutters shadow">
        <div className="col-12">
          <div className="accordion" id="accordionExample">
            <div className="card">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  <button className="btn btn-link title" type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne" aria-expanded="true"
                          aria-controls="collapseOne">
                    Place en crèche
                  </button>
                </h5>
              </div>
              <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                   data-parent="#accordionExample">
                <div className="card-body">
                  <p>Votre service Ressources Humaines met à votre disposition des places en crèche
                    pour les salariés répondant à ces critères :</p>
                  <ul>
                    <li>Avoir un enfant de 3 ans ou moins ou attendre un enfant</li>
                    <li>Etre salarié en CDI au sein d'URBASOLAR et période d'essai validée </li>
                    <li>Avoir ou être sur le point d'effectuer une demande d'inscription en crèche municipale (fournir un justificatif d'inscription ou une attestation sur l'honneur ou la copie de courrier de liste d'attente/de refus)</li>
                    <li>Ne pas être en préavis de départ au moment de l'inscription</li>
                  </ul>
                  <a href={`${process.env.REACT_APP_URL_COMMISSION}/urbasolar/questionnaire`} target="_blank" rel="noopener noreferrer">S'inscrire pour obtenir une place en crèche</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingTwo">
                <h5 className="mb-0">
                  <button className="btn btn-link title collapsed" type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo" aria-expanded="false"
                          aria-controls="collapseTwo">
                    Garde complémentaire
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                   data-parent="#collapseTwo">
                <div className="card-body">
                  <p className={'mb-0'}>Besoin d'une solution de garde pour vos enfants ?</p>
                  <p className={'mb-0'}>Sortie d'école, sortie de crèche, enfants malades, vacances scolaires, nous mettons à votre disposition un service de complément de garde pour vous permettre de mieux concilier vie perso et vie pro.</p>
                  <p>Grâce à votre employeur URBASOLAR, vous bénéficiez ainsi d'un accès privilégié à la plateforme O2, le leader du service à domicile depuis 20 ans.</p>
                  <p>Les avantages :</p>
                  <ul>
                    <li>4h de prestation offertes</li>
                    <li>Aides de la CAF</li>
                    <li>Possibilité d'un crédit d'impôts de 50%</li>
                    <li>Aucune gestion administrative</li>
                    <li>Des gardes d'enfants formées aux gestes de premiers secours à l'enfant</li>
                    <li>Une méthode ludo éducative approuvée</li>
                    <li>Un devis personnalisé</li>
                    <li>Une visite gratuite d'évaluation du besoin à domicile</li>
                  </ul>
                  <p>Comment faire pour en bénéficier ?</p>
                  <p>Rendez-vous sur la page dédiée de notre partenaire en cliquant sur en savoir plus : <a href={'https://www.o2.fr/lp/partenaires/maplaceencreche#/2-coordonnees-rappel-immediat'} target={"_blank"}>Bénéficier du service</a></p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingThree">
                <h5 className="mb-0">
                  <button className="btn btn-link title collapsed" type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree" aria-expanded="false"
                          aria-controls="collapseThree">
                    Activités pour mes enfants
                  </button>
                </h5>
              </div>
              <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                   data-parent="#accordionExample">
                <div className="card-body">
                  <p>Que vous souhaitiez une idée d'activité créative avec votre plus jeune enfant ou faire du sport en famille. Initier vos plus grands à la culture ou obtenir des idées de plats à préparer à avec vos plus grands. Cette plateforme est faite pour vous !</p>
                  <p>Naviguez, cliquez, découvrez dans les différentes rubriques des trucs et des astuces, des exemples d’activités, des histoires, des chansons et des liens utiles, adaptés à l’âge de votre enfant et qui seront utiles à votre quotidien. <a href={process.env.REACT_APP_URL_UJCALC} target={'_blank'}>Découvrir</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }

  const getSection3 = () => {
    return (
      <div className="container">
      <div className="no-gutters question-mail shadow block-txt-simple2">
        <div className="col-12">
          <div className="sizetext d-flex justify-content-center p-3 margin--p plus">
            <p><span className="color-mpec">Vous avez des questions ? Contactez votre experte famille Aline Loye : </span><br/>
              <a href="mailto:aline.loye@maplaceencreche.com" target="_blank" rel="noopener noreferrer"
                 className="d-flex flex-row underline justify-content-center">aline.loye@maplaceencreche.com
              </a>
              06 98 95 92 54</p>
          </div>
        </div>
      </div>
      </div>
    )
  }

  return (
    <div>
      <div className="m-auto ioej">
        <img src={logo_urbasolar} alt="logo-urbasolar" className="logo-urbasolar"/>
      </div>
      <div className={'container-fluid'}>
        <div className={'row'}>
          <div className={'col-12 no-pad'}>
            <BackgroundImg
              src="petite_fille_robe_bleu.jpg"
              style={{ background: '50% 50% / cover no-repeat' }}
            >
              <header className="masthead bg-header w-100">
                <div className="d-flex flex-column justify-content-center">
                  <div className={'d-flex col-12 col-sm-3 justify-content-center mb-sm-0 mb-4'}>
                    <img src={logo_parents_zens_rond} alt="logo-pz" className="logo-pz"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="blueround shadow">
                      <p className="text-uppercase nav-head">vos services parentalité</p>
                    </div>
                  </div>
                </div>
              </header>
            </BackgroundImg>
          </div>
          <Section classSup={'bg-blue w-100'} content={getContentBlueBand()}/>
          <div className="container">
            <Section content={getSection1()} classSup={'max-h mb-5'}/>
            <Section classSup={'w-100'} content={getSection2()}/>
            <Section classSup={'w-100'} content={getSection3()}/>
          </div>
          <footer className="block-section w-100">
            <div className="col-12 col-sm-7">
                    <span className="type--fine-print">©
                        <span className="update-year">2019</span> maplaceencrèche
                    </span>
              <a className="type--fine-print" href={`${process.env.REACT_APP_URL_MPEC}/legale/cgu`} target="_blank"
                 rel="noopener noreferrer"> CGU</a>
              <a className="type--fine-print" href={`${process.env.REACT_APP_URL_MPEC}/legale/mentions-legales`}
                 target="-_blank" rel="noopener noreferrer"> Mentions légales</a>
              <a className="type--fine-print"
                 href={`${process.env.REACT_APP_URL_MPEC}/qui-sommes-nous/notre-mission/`} target="_blank"
                 rel="noopener noreferrer"> Notre mission</a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default App
