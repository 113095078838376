import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { CloudimageProvider } from 'react-cloudimage-responsive'
import App from './App'
import * as serviceWorker from './serviceWorker'

const cloudimgConfig = {
  token: 'ce86cc75c',
  baseURL: '_origin_/ms/urbasolar/',
  presets: {
    xs: '(max-width: 575px)', // up to 575    PHONE
    sm: '(min-width: 576px)', // 576 - 767    PHABLET
    md: '(min-width: 768px)', // 768 - 991    TABLET
    lg: '(min-width: 992px)', // 992 - 1199   SMALL_LAPTOP_SCREEN
    lgMpec: '(min-width: 1024px)', // 992 - 1199   SMALL_LAPTOP_SCREEN
    xl: '(min-width: 1200px)', // from 1200    USUALSCREEN
    xxl: '(min-width: 1600px)' // from 1200    USUALSCREEN
  }
}

ReactDOM.render(
  <CloudimageProvider config={cloudimgConfig}>
    <App/>
  </CloudimageProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
